<template>
    <div class="imprint">
        <h2>Imprint</h2>
        <p>Juri Otto Orson Welz</p>
        <p>Friedrichstra&szlig;e 122</p>
        <p>10117 Berlin</p>
        <p><a href="mailto:juriwelz@gmail.com">juriwelz@gmail.com</a></p>
        <button @click="$router.go(-1)">Go Back</button>
    </div>
</template>

<script>
    export default {
        name: "imprint"
    }
</script>

<style scoped>
    .imprint{
        margin: 50px 0;
    }

    button{
        background: lightgrey;
        border: none;
        padding: 10px 20px;
    }

</style>