<template>
    <div class="c-v-block">
        <div class="block-date">
            <slot name="block-date"></slot>
        </div>
        <div class="block-title">
            <slot name="block-title"></slot>
        </div>
        <div class="block-content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CVBlock"
    }
</script>

<style scoped lang="scss">
    .c-v-block {
    margin: 30px 0 50px;
    text-align: left;
    background-color: #f1f1f1;
    padding: 60px;
    }

    .block-title{
        font-size: larger;
        padding-top: 17px;
        padding-bottom: 10px;
    }

    .block-date {
        font-style: italic;
        padding-left: 1em;
    }

    .block-content {
        padding-left: 1em;

        div {
            margin-top: 17px;
        }
    }

</style>