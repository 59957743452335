<template>
    <div class="completeTitle">
        <deeeps></deeeps>
<!--        <subtitle></subtitle>-->
    </div>
</template>

<script>
    import deeeps from "@/components/deeeps";
    // import subtitle from "@/components/subtitle";

    export default {
        name: "completeTitle",
        components: {
            deeeps
        }

    }
</script>

<style scoped>

</style>