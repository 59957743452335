<template>
    <footer>
        <span>&copy; Juri Welz 2020.</span>
        <span>
        <router-link to="imprint">Impressum</router-link>
            </span>
    </footer>
</template>

<script>
    export default {
        name: "pageFooter"
    }
</script>

<style scoped>
    footer{
        overflow-wrap: break-spaces;
        margin-top: 10px;
    }

    span{
        padding: 2px;
        overflow-wrap: break-spaces;
    }


</style>