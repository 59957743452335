<template class="deeeps">
    <h1 id="title">
        <span id="juri">Die <span class="deeeps">DEEEPs</span></span>
    </h1>
</template>

<script>
    export default {
        name: "deeeps"
    }
</script>

<style lang="scss" scoped>
    @import "../assets/master";

    #title {
        width: 100%;
        //border: solid black 1px;
        //display: flex;
        justify-content: center;
        //flex-wrap: wrap;
        transition: all 1s;

        span {
            //border: solid black 1px;
            font-family: sans-serif monospace;
            font-size: $TITLE_SIZE;
            display: inline-block;
            //flex: 1;
            margin: 0 20px;
            //float: left;

            transition: all 1s;
        }
    }


    @media screen and (max-width: $XL_MAX) {
        #title > span {
            display: block;
            transition: all 1s;
        }


    }

    @media screen and (min-width: $XL_MIN) {
        #title {
            margin-top: 30vh;
            transition: all 1s;
        }

    }

    @media screen and (max-width: $S_MAX) {
        #title span {
            font-size: $TITLE_SIZE_S;
            margin: 0;
            transition: all 1s;
        }

    }

</style>