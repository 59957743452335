<template>
    <div class="history">
        <h2>History</h2>
        <article>

            <c-v-block>
                <template v-slot:block-date>23. Dezember 2020</template>
                <template v-slot:block-title>Denkwürdiges Einundzwanzigste Eins Spiel</template>
                <div>Zuerst flog Juri raus. Dann Steffen, dann Ole, dann Paul. Gewonnen hat Max, Zweiter wurde Johannes.</div>
            </c-v-block>

        </article>
    </div>
</template>

<script>
    import CVBlock from "@/components/CVBlock";

    export default {
        name: "history",
        components: {CVBlock}
    }
</script>

<style scoped>
    h3 {
        padding: 30px 0 10px;
    }

    article {
        text-align: center;
        max-width: 800px;
        margin: 0 auto;
    }
</style>