import Vue from 'vue'
import VueRouter from 'vue-router'
//import App from "../App.vue"
// import life from "@/components/life"
import completeTitle from "@/components/completeTitle";
// import study from "@/components/study";
import imprint from "@/components/imprint";
import history from "@/components/history"


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 0,
    components: {top: completeTitle, bottom: ""}
  },
  // {
  //   path: '/life',
  //   name: 1,
  //   components: {top: "", bottom: life}
  // },
  // {
  //   path: '/study',
  //   name: 2,
  //   components: {top: "", bottom: study}
  // },
  {
    path: '/history',
    name: 3,
    components: {top: "", bottom: history}
  },
  {
    path: '/imprint',
    name: 'imprint',
    components: {top: "", bottom: imprint}
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
